import logo from "../logo.svg";
import "../assets/Home.css";
import "../assets/Home_PAR01.css";
import React, { useState } from "react";
import axios from "axios";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function Print_PRO01() {
  const [printerName, onPrinterName] = React.useState("FRBICOUP01");
  const [label, onLabel] = React.useState("");
  const [value, onValue] = React.useState("");
  const label_ref = React.useRef(null);

  React.useEffect(() => {
    label_ref.current?.focus();
  }, []);

  const [confirmationMessage, setConfirmationMessage] = useState("");

  const showConfirmationMessage = (message) => {
    setConfirmationMessage(message);
    setTimeout(() => {
      setConfirmationMessage("");
    }, 10000); // Le message disparaît après 10 secondes
  };

  const print_qrcode = async (e) => {
    e.preventDefault();

    const response = await axios
      .get(
        "https://impression-qrcode/print_qrcode_manuel/" +
          label +
          "/FRBICOUP01/1/",
        {
          value: value,
          value2: "None",
        }
      )
      .then((response) => {
        console.log("POST OK");
        onLabel("");
        onValue("");
        label_ref.current?.focus();
        showConfirmationMessage("Impréssion OK");
      });
  };

  return (
    <div className="App">
      <form id="form" method="post" onSubmit={print_qrcode}>
        <label htmlFor="site_stk">Imprimantes :</label>
        <select
          id="printerName"
          name="printerName"
          value={printerName}
          onChange={(e) => onPrinterName(e.target.value)}
        >
          <option value="FRBICOUP01">FRBICOUP01</option>
          <option value="FRBITRAC01">FRBITRAC01</option>
          <option value="FRBITRAC02">FRBITRAC02</option>
        </select>
        <label htmlFor="loc_itm">Label QrCode :</label>
        <input
          type="text"
          id="label"
          name="label"
          value={label}
          ref={label_ref}
          onChange={(e) => onLabel(e.target.value)}
          required
        />
        <label htmlFor="ref_itm">Valeur QrCode :</label>
        <label id="des_itm"></label>
        <input
          type="text"
          id="value"
          name="value"
          value={value}
          onChange={(e) => onValue(e.target.value)}
          required
        />
        <button type="submit" id="post">
          Impréssion
        </button>
      </form>
      {confirmationMessage && (
        <p className="message-confirmation">{confirmationMessage}</p>
      )}
    </div>
  );
}

export default Print_PRO01;
