// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="text"],
input[type="email"],
input[type="submit"],
input[type="password"],
button {
  padding: 0.5em;
  font: inherit;
  line-height: 1;
}

button {
  cursor: pointer;
}

p {
  margin: 0;
}

p:not(:last-child) {
  margin-bottom: 1.5em;
}

.dialog {
  max-width: 20rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  position: relative;
}

.dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.dialog-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}

.input-update {
  width: 250px;
}

table {
  width: 100%;
}

input {
  width: 80%;
}
`, "",{"version":3,"sources":["webpack://./src/assets/InvCp.css"],"names":[],"mappings":"AAAA;;;;;EAKE,cAAc;EACd,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ","sourcesContent":["input[type=\"text\"],\ninput[type=\"email\"],\ninput[type=\"submit\"],\ninput[type=\"password\"],\nbutton {\n  padding: 0.5em;\n  font: inherit;\n  line-height: 1;\n}\n\nbutton {\n  cursor: pointer;\n}\n\np {\n  margin: 0;\n}\n\np:not(:last-child) {\n  margin-bottom: 1.5em;\n}\n\n.dialog {\n  max-width: 20rem;\n  padding: 2rem;\n  border: 0;\n  border-radius: 0.5rem;\n  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;\n  position: relative;\n}\n\n.dialog::backdrop {\n  background: hsl(0 0% 0% / 50%);\n}\n\n.dialog-close-btn {\n  font-size: 0.75em;\n  position: absolute;\n  top: 0.25em;\n  right: 0.25em;\n}\n\n.input-update {\n  width: 250px;\n}\n\ntable {\n  width: 100%;\n}\n\ninput {\n  width: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
