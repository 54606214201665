import React from "react";
import axios from "axios";
import "../assets/ItemInfo.css";

function ItemInfo() {
  const [data, setData] = React.useState([]);
  const [value_search, setValue_search] = React.useState("MP001010");
  const [item_ref, setItem_ref] = React.useState("");
  const [item_search, setItem_search] = React.useState("");
  const [item_des, setItem_des] = React.useState("");

  function getItem_info() {
    const loadingModal = document.querySelector("#loadingModal");
    loadingModal.showModal();

    axios
      .get(
        `https://inventaire-annuel-api.protecop.net/api_v1/item_info/` +
          value_search +
          ``
      )
      .then((res) => {
        const data = res.data;
        setData(data);
        console.log("GET OK");
        setItem_ref(data[0].itm_ref);
        setItem_search(data[0].search_key);
        setItem_des(data[0].itm_des);
        loadingModal.close();
      })
      .catch((error) => {
        console.log(error);
        setItem_ref("Aucune information");
        setItem_search("Aucune information");
        setItem_des("Aucune information");
        loadingModal.close();
      });
  }

  return (
    <div>
      <dialog id="loadingModal" class="dialog">
        <p>Chargement en cours</p>
      </dialog>
      <h1 id="titre">Recherche d'une référence article SageX3</h1>
      <label htmlFor="select_cr" id="cr_itm_lbl">
        Référence SageX3 :{" "}
      </label>
      <input
        type="text"
        id="search"
        name="search"
        value={value_search}
        onChange={(e) => setValue_search(e.target.value)}
        required
      />
      <button
        className="button button1"
        id="valid_search"
        onClick={getItem_info}
      >
        Valider la recherche
      </button>

      <table id="list-rcp">
        <thead>
          <tr>
            <th>Référence SageX3</th>
            <th>Clé de recherche</th>
            <th>Désignation article</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{item_ref}</td>
            <td>{item_search}</td>
            <td>{item_des}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ItemInfo;
