import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Layout from "./components/Layout";
import Layout_PAR01 from "./components/Layout_PAR01";
import Layout_PRO02 from "./components/Layout_PRO02";
import Layout_PRO01 from "./components/Layout_PRO01";
import Layout_PRI01 from "./components/Layout_PRI01";
import NoPage from "./components/NoPage";
import Home from "./pages/Home";
import InvCp_PAR01 from "./pages/InvCp_PAR01";
import Home_PAR01 from "./pages/Home_PAR01";
import ListRcp_PAR01 from "./pages/ListRcp_PAR01";
import ListRcp_PRO02 from "./pages/ListRcp_PRO02";
import ListRcp_PRO01 from "./pages/ListRcp_PRO01";
import Home_PRO02 from "./pages/Home_PRO02";
import InvCp_PRO02 from "./pages/InvCp_PRO02";
import Home_PRO01 from "./pages/Home_PRO01";
import InvCp_PRO01 from "./pages/InvCp_PRO01";
import Home_PRI01 from "./pages/Home_PRI01";
import InvCp_PRI01 from "./pages/InvCp_PRI01";
import ItemInfo from "./pages/ItemInfo";
import CpUpdate_PAR01 from "./pages/CpUpdate_PAR01";
import CpUpdate_Glb from "./pages/CpUpdate_Glb";
import Valid_list from "./pages/Valid_list";
import Print_PRO01 from "./pages/Print_PRO01";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="CpUpdate_Glb" element={<CpUpdate_Glb />} />
          <Route path="ItemInfo" element={<ItemInfo />} />
          <Route path="Valid_list" element={<Valid_list />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="PAR01/" element={<Layout_PAR01 />}>
          <Route index element={<Home_PAR01 />} />
          <Route path="InvCp_PAR01" element={<InvCp_PAR01 />} />
          <Route path="Home_PAR01" element={<Home_PAR01 />} />
          <Route path="ListRcp_PAR01" element={<ListRcp_PAR01 />} />
          <Route path="CpUpdate_PAR01" element={<CpUpdate_PAR01 />} />
          <Route path="ItemInfo" element={<ItemInfo />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="PRO02/" element={<Layout_PRO02 />}>
          <Route index element={<Home_PRO02 />} />
          <Route path="Home_PRO02" element={<Home_PRO02 />} />
          <Route path="InvCp_PRO02" element={<InvCp_PRO02 />} />
          <Route path="ListRcp_PRO02" element={<ListRcp_PRO02 />} />
          <Route path="ItemInfo" element={<ItemInfo />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="PRO01/" element={<Layout_PRO01 />}>
          <Route index element={<Home_PRO01 />} />
          <Route path="Home_PRO01" element={<Home_PRO01 />} />
          <Route path="InvCp_PRO01" element={<InvCp_PRO01 />} />
          <Route path="ListRcp_PRO01" element={<ListRcp_PRO01 />} />
          <Route path="Print_PRO01" element={<Print_PRO01 />} />
          <Route path="ItemInfo" element={<ItemInfo />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="PRI01/" element={<Layout_PRI01 />}>
          <Route index element={<Home_PRI01 />} />
          <Route path="Home_PRI01" element={<Home_PRI01 />} />
          <Route path="InvCp_PRI01" element={<InvCp_PRI01 />} />
          <Route path="ItemInfo" element={<ItemInfo />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
