import React from "react";
import axios from "axios";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import "../assets/InvCp.css";

function InvCp() {
  const [data, setData] = React.useState([]);
  const [stk_site, setstk_site] = React.useState("PRI01");

  React.useEffect(() => {
    getIdu_as_trac_bal();
  }, []);

  function getIdu_as_trac_bal() {
    const loadingModal = document.querySelector("#loadingModal");
    loadingModal.showModal();

    axios
      .get(
        `https://inventaire-annuel-api.protecop.net/api_v1/qte_cp_by_site/` +
          stk_site +
          ``
      )
      .then((res) => {
        const data = res.data;
        setData(data);
        loadingModal.close();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Recherche:{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} resultats...`}
          style={{
            fontSize: "1.1rem",
            border: "0",
          }}
        />
      </span>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Recherche ${count} resultats...`}
      />
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Emplacement",
        accessor: "itm_loc",
      },
      {
        Header: "Reference article",
        accessor: "itm_ref",
      },
      {
        Header: "Cle de recherche",
        accessor: "search_key",
      },
      {
        Header: "Lot",
        accessor: "itm_lot",
      },
      {
        Header: "Quantite comptee",
        accessor: "qte_cp",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div>
      <dialog id="loadingModal" class="dialog">
        <p>Chargement en cours</p>
      </dialog>

      <h1>Articles comptees (PRI01)</h1>

      <table
        {...getTableProps()}
        style={{ border: "solid 1px black", width: "100%" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: "solid 3px red",
                    color: "black",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default InvCp;
