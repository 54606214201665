import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

function ListRcp_PRO02() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editableData, setEditableData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Remplacez cette URL par votre véritable endpoint API
        const response = await axios.get(
          "https://inventaire-annuel-api.protecop.net/api_v1/rcp_by_site/PRO02"
        );
        setData(response.data.slice(0, 500));
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handleUpdate = async (item) => {
    try {
      const updateqte_cp = editableData[item.id] || "";
      // Créez une copie de l'élément avec la mise à jour
      const updatedItem = {
        ...item,
        // Vous pouvez personnaliser ici quel champ est mis à jour
        qte_cp: updateqte_cp || item.qte_cp,
        itm_loc: item.itm_loc,
        itm_lot: item.itm_lot,
        status_line: "comptee",
      };

      // Requête PUT vers l'API
      const response = await axios.put(
        `https://inventaire-annuel-api.protecop.net/api_v1/update_article_cp/${item.id}` +
          `/`,
        updatedItem
      );

      // Mettre à jour le state local
      setData((prevData) => prevData.filter((data) => data.id !== item.id));
      // Notification de succès
      //toast.success(`Élément ${item.id} mis à jour avec succès !`);
    } catch (err) {
      // Gérer les erreurs de mise à jour
      //toast.error(`Erreur lors de la mise à jour : ${err.message}`);
      console.error("Erreur de mise à jour :", err);
    }
  };

  const handleEditableChange = (id, value) => {
    setEditableData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur de chargement : {error.message}</div>;

  return (
    <div className="p-4">
      <table className="w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Emplacement</th>
            <th className="border p-2">Référence Article</th>
            <th className="border p-2">Lot</th>
            <th className="border p-2">Qte Comptée</th>
            <th className="border p-2">Qte Recomptée</th>
            <th className="border p-2">Validation recomptage</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="hover:bg-gray-100">
              <td className="border p-2">{item.itm_loc}</td>
              <td className="border p-2">{item.itm_ref}</td>
              <td className="border p-2">{item.itm_lot}</td>
              <td className="border p-2">{item.qte_cp}</td>
              <td className="border p-2">
                <input
                  type="number"
                  value={editableData[item.id] || ""}
                  onChange={(e) =>
                    handleEditableChange(item.id, e.target.value)
                  }
                  className="w-full p-1 border rounded"
                  placeholder="Saisir une valeur"
                />
              </td>
              <td className="border p-2">
                <button onClick={() => handleUpdate(item)} className="">
                  Mettre à jour
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListRcp_PRO02;
