import logo from "../logo.svg";
import "../assets/Home.css";
import "../assets/Home_PAR01.css";
import React, { useState } from "react";
import axios from "axios";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function Home_PRI01() {
  const [site_stk, onSite_stk] = React.useState("PRI01");
  const [loc_itm, onLoc_itm] = React.useState("");
  const [ref_itm, onRef_itm] = React.useState("");
  const [qte_itm, onQte_itm] = React.useState();
  const [lot_itm, onLot_itm] = React.useState("");

  const loc_itm_ref = React.useRef(null);

  React.useEffect(() => {
    loc_itm_ref.current?.focus();
  }, []);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const showConfirmationMessage = (message) => {
    setConfirmationMessage(message);
    setTimeout(() => {
      setConfirmationMessage("");
    }, 10000); // Le message disparaît après 10 secondes
  };

  const post_article_cp = async (e) => {
    //const str_site_stk = new String(site_stk);
    //const str_loc_itm = new String(loc_itm);
    //const str_ref_itm = new String(ref_itm);
    //site_stk: str_site_stk.toUpperCase(),
    e.preventDefault();

    const response = await axios
      .post("https://inventaire-annuel-api.protecop.net/api_v1/article_cp/", {
        stk_site: site_stk,
        itm_loc: loc_itm.toUpperCase(),
        itm_ref: ref_itm.toUpperCase(),
        qte_cp: qte_itm,
        itm_lot: lot_itm,
        status_line: "comptee",
      })
      .then((response) => {
        console.log("POST OK");
        onRef_itm("");
        onLoc_itm("");
        onQte_itm("");
        onLot_itm("");
        loc_itm_ref.current?.focus();
        showConfirmationMessage("Article enregistré avec succès");
      });
  };

  const [formData, setFormData] = useState({
    site_stk: "PRI01",
    loc_itm: "",
    ref_itm: "",
    lot_itm: "",
    qte_itm: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("Form data:", formData);
  };

  return (
    <div className="App">
      <form id="form" method="post" onSubmit={post_article_cp}>
        <label htmlFor="site_stk">Site :</label>
        <select
          id="site_stk"
          name="site_stk"
          value={site_stk}
          onChange={(e) => onSite_stk(e.target.value)}
        >
          <option value="PRI01">PRI01</option>
        </select>
        <label htmlFor="loc_itm">Emplacement :</label>
        <input
          type="text"
          id="loc_itm"
          name="loc_itm"
          value={loc_itm}
          ref={loc_itm_ref}
          onChange={(e) => onLoc_itm(e.target.value)}
          required
        />
        <label htmlFor="ref_itm">Référence article :</label>
        <label id="des_itm"></label>
        <input
          type="text"
          id="ref_itm"
          name="ref_itm"
          value={ref_itm}
          onChange={(e) => onRef_itm(e.target.value)}
          required
        />
        <label htmlFor="lot_itm">Lot article :</label>
        <input
          type="text"
          id="lot_itm"
          name="lot_itm"
          value={lot_itm}
          onChange={(e) => onLot_itm(e.target.value)}
        />
        <label htmlFor="qte_itm">Qte :</label>
        <input
          type="number"
          id="qte_itm"
          name="qte_itm"
          value={qte_itm}
          onChange={(e) => onQte_itm(e.target.value)}
          step="any"
          required
        />
        <button type="submit" id="post">
          Valider le comptage
        </button>
      </form>
      {confirmationMessage && (
        <p className="message-confirmation">{confirmationMessage}</p>
      )}
    </div>
  );
}

export default Home_PRI01;
