import { Outlet, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

const Layout_PRO02 = () => {
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/PRO02">Inventaire annuel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/PRO02/Home_PRO02">Saisie (PRO02)</Nav.Link>
              <Nav.Link href="/PRO02/InvCp_PRO02">
                Liste comptée (PRO02)
              </Nav.Link>
              <Nav.Link href="/PRO02/ListRcp_PRO02">Recomptage</Nav.Link>
              <Nav.Link href="/PRO02/ItemInfo">Information article</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default Layout_PRO02;
