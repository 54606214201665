// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="text"],
input[type="email"],
input[type="submit"],
input[type="password"],
button {
  padding: 0.5em;
  font: inherit;
  line-height: 1;
  font-size: 24px;
}

button {
  cursor: pointer;
}

p {
  margin: 0;
}

p:not(:last-child) {
  margin-bottom: 1.5em;
}

.dialog {
  max-width: 20rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;
  position: relative;
}

.dialog::backdrop {
  background: hsl(0 0% 0% / 50%);
}

.dialog-close-btn {
  font-size: 0.75em;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
}

.input-update {
  width: 250px;
}

table {
  width: 100%;
}

.button {
  width: 100%;
  font-size: 26px;
}

td {
  font-size: 22px;
}

th {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/ItemInfo.css"],"names":[],"mappings":"AAAA;;;;;EAKE,cAAc;EACd,aAAa;EACb,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,iDAAiD;EACjD,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["input[type=\"text\"],\r\ninput[type=\"email\"],\r\ninput[type=\"submit\"],\r\ninput[type=\"password\"],\r\nbutton {\r\n  padding: 0.5em;\r\n  font: inherit;\r\n  line-height: 1;\r\n  font-size: 24px;\r\n}\r\n\r\nbutton {\r\n  cursor: pointer;\r\n}\r\n\r\np {\r\n  margin: 0;\r\n}\r\n\r\np:not(:last-child) {\r\n  margin-bottom: 1.5em;\r\n}\r\n\r\n.dialog {\r\n  max-width: 20rem;\r\n  padding: 2rem;\r\n  border: 0;\r\n  border-radius: 0.5rem;\r\n  box-shadow: hsl(0 0% 0% / 10%) 0 0 0.5rem 0.25rem;\r\n  position: relative;\r\n}\r\n\r\n.dialog::backdrop {\r\n  background: hsl(0 0% 0% / 50%);\r\n}\r\n\r\n.dialog-close-btn {\r\n  font-size: 0.75em;\r\n  position: absolute;\r\n  top: 0.25em;\r\n  right: 0.25em;\r\n}\r\n\r\n.input-update {\r\n  width: 250px;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n}\r\n\r\n.button {\r\n  width: 100%;\r\n  font-size: 26px;\r\n}\r\n\r\ntd {\r\n  font-size: 22px;\r\n}\r\n\r\nth {\r\n  font-size: 18px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
