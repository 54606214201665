import { Outlet, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

const Layout_PRO01 = () => {
  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/PRO01">Inventaire annuel</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/PRO01/Home_PRO01">Saisie (PRO01)</Nav.Link>
              <Nav.Link href="/PRO01/InvCp_PRO01">
                Liste comptée (PRO01)
              </Nav.Link>
              <Nav.Link href="/PRO01/ListRcp_PRO01">Recomptage</Nav.Link>
              <Nav.Link href="/PRO01/ItemInfo">Information article</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default Layout_PRO01;
