import logo from "../logo.svg";
import "../assets/Home.css";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function Valid_list() {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState({});
  const [selectedAdditionalRows, setSelectedAdditionalRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState(null);
  const [postSuccess, setPostSuccess] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Remplacez cette URL par votre endpoint API réel
        const response = await axios.get(
          "https://inventaire-annuel-api.protecop.net/api_v1/valid_list/"
        );

        // Limitez à 10 entrées pour l'exemple
        const limitedData = response.data.slice(0, 5000);

        setData(limitedData);

        // Initialise l'état des cases à cocher
        const initialSelectedState = limitedData.reduce((acc, item) => {
          acc[item.id] = false;
          return acc;
        }, {});

        setSelectedRows(initialSelectedState);
        setSelectedAdditionalRows(initialSelectedState);
        setLoading(false);
      } catch (err) {
        setError("Erreur lors de la récupération des données");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [filters, setFilters] = useState({
    id: "",
    stk_site: "",
    itm_loc: "",
    itm_ref: "",
    search_key: "",
  });

  const handleCheckboxChange = (id, type) => {
    if (type === "primary") {
      setSelectedRows((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    } else {
      setSelectedAdditionalRows((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      // Conversion des filtres en chaînes de caractères minuscules pour la comparaison
      const idMatch =
        filters.id === "" ||
        item.id.toString().toLowerCase().includes(filters.id.toLowerCase());

      const stk_siteMatch =
        filters.stk_site === "" ||
        item.stk_site.toLowerCase() === filters.stk_site.toLowerCase();

      const itm_locMatch =
        filters.itm_loc === "" ||
        item.itm_loc
          .toString()
          .toLowerCase()
          .includes(filters.itm_loc.toLowerCase());

      const itm_refMatch =
        filters.itm_ref === "" || item.itm_ref.startsWith(filters.itm_ref);

      const search_keyMatch =
        filters.search_key === "" ||
        item.search_key
          .toLowerCase()
          .includes(filters.search_key.toLowerCase());

      return (
        idMatch &&
        stk_siteMatch &&
        itm_locMatch &&
        itm_refMatch &&
        search_keyMatch
      );
    });
  }, [data, filters]);

  const handleSubmitSelectedRows = async () => {
    // Récupérer les données complètes des lignes sélectionnées
    const selectedRowsData = data
      .filter((item) => selectedRows[item.id])
      .map((item) => ({
        id: item.id,
        status_line: "validation",
        itm_loc: item.itm_loc,
        qte_cp: item.qte_cp,
      }));

    const selectedRowsData2 = data
      .filter((item) => selectedAdditionalRows[item.id])
      .map((item) => ({
        id: item.id,
        status_line: "recomptage",
        itm_loc: item.itm_loc,
        qte_cp: item.qte_cp,
      }));

    if (selectedRowsData.length === 0 && selectedRowsData2.length === 0) {
      alert("Aucune ligne sélectionnée");
      return;
    }
    if (selectedRowsData.length != 0) {
      try {
        setLoading(true);

        // Envoi de la liste JSON complète
        const response = await axios.put(
          "https://inventaire-annuel-api.protecop.net/api_v1/update_status/",
          selectedRowsData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Mettre à jour les données locales
        const updatedData = data.map((item) => {
          const updatedItem = selectedRowsData.find(
            (selected) => selected.id === item.id
          );
          return updatedItem ? { ...item, ...updatedItem } : item;
        });

        setData(updatedData);
        // Réinitialiser les cases à cocher
        setSelectedRows(
          Object.keys(selectedRows).reduce((acc, id) => {
            acc[id] = false;
            return acc;
          }, {})
        );
        setSelectedAdditionalRows(
          Object.keys(selectedAdditionalRows).reduce((acc, id) => {
            acc[id] = false;
            return acc;
          }, {})
        );

        alert(`Mise à jour réussie pour ${selectedRowsData.length} ligne(s)`);
      } catch (err) {
        console.error("Erreur lors de la mise à jour", err);
        alert("Erreur lors de la mise à jour");
      } finally {
        setLoading(false);
      }
    }

    if (selectedRowsData2.length != 0) {
      try {
        setLoading(true);

        // Envoi de la liste JSON complète
        const response = await axios.put(
          "https://inventaire-annuel-api.protecop.net/api_v1/update_status/",
          selectedRowsData2,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Mettre à jour les données locales
        const updatedData = data.map((item) => {
          const updatedItem = selectedRowsData2.find(
            (selected) => selected.id === item.id
          );
          return updatedItem ? { ...item, ...updatedItem } : item;
        });

        setData(updatedData);

        // Réinitialiser les cases à cocher
        setSelectedRows(
          Object.keys(selectedAdditionalRows).reduce((acc, id) => {
            acc[id] = false;
            return acc;
          }, {})
        );
        setSelectedAdditionalRows(
          Object.keys(selectedAdditionalRows).reduce((acc, id) => {
            acc[id] = false;
            return acc;
          }, {})
        );
        alert(`Mise à jour réussie pour ${selectedRowsData2.length} ligne(s)`);
      } catch (err) {
        console.error("Erreur lors de la mise à jour", err);
        alert("Erreur lors de la mise à jour");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      id: "",
      stk_site: "",
      itm_loc: "",
      itm_ref: "",
      search_key: "",
    });
  };

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <button
          onClick={handleSubmitSelectedRows}
          disabled={postLoading}
          className=""
        >
          {postLoading
            ? "Envoi en cours..."
            : "Mettre à jour les lignes sélectionnées"}
        </button>
        {postError && <p className="text-red-500 mt-2">{postError}</p>}
        {postSuccess && (
          <p className="text-green-500 mt-2">Données envoyées avec succès !</p>
        )}
        <button onClick={resetFilters} className="">
          Réinitialiser les filtres
        </button>
      </div>
      <div className="mb-4 grid grid-cols-5 gap-2">
        <input
          type="text"
          placeholder="Filtrer par ID"
          value={filters.id}
          onChange={(e) => handleFilterChange("id", e.target.value)}
          className="border p-2"
        />
        <input
          type="text"
          placeholder="Filtrer par Site"
          value={filters.stk_site}
          onChange={(e) => handleFilterChange("stk_site", e.target.value)}
          className="border p-2"
        />
        <input
          type="text"
          placeholder="Filtrer par Emplacement"
          value={filters.itm_loc}
          onChange={(e) => handleFilterChange("itm_loc", e.target.value)}
          className="border p-2"
        />
        <input
          type="text"
          placeholder="Filtrer par Réf SageX3"
          value={filters.itm_ref}
          onChange={(e) => handleFilterChange("itm_ref", e.target.value)}
          className="border p-2"
        />
      </div>
      <div className="mb-4 text-gray-600">
        Résultats: {filteredData.length} / {data.length}
      </div>
      <table className="w-full border-collapse border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Id</th>
            <th className="border p-2">Site</th>
            <th className="border p-2">Emplacement</th>
            <th className="border p-2">Réf SageX3</th>
            <th className="border p-2">Clé de recherche</th>
            <th className="border p-2">Lot</th>
            <th className="border p-2">Qte comptées</th>
            <th className="border p-2">Qte StockX3</th>
            <th className="border p-2">status ligne</th>
            <th className="border p-2">Validation</th>
            <th className="border p-2">Recomptage</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id} className="hover:bg-gray-100">
              <td className="border p-2">{item.id}</td>
              <td className="border p-2">{item.stk_site}</td>
              <td className="border p-2">{item.itm_loc}</td>
              <td className="border p-2">{item.itm_ref}</td>
              <td className="border p-2">{item.search_key}</td>
              <td className="border p-2">{item.itm_lot}</td>
              <td className="border p-2">{item.qte_cp}</td>
              <td className="border p-2">{item.qte_stock}</td>
              <td className="border p-2">{item.status_line}</td>
              <td className="border p-2 text-center">
                <input
                  type="checkbox"
                  checked={selectedRows[item.id] || false}
                  onChange={() => handleCheckboxChange(item.id, "primary")}
                  className="form-checkbox h-5 w-5"
                />
              </td>
              <td className="border p-2 text-center">
                <input
                  type="checkbox"
                  checked={selectedAdditionalRows[item.id] || false}
                  onChange={() => handleCheckboxChange(item.id, "additional")}
                  className="form-checkbox h-5 w-5"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Valid_list;
